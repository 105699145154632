
import {defineComponent, onMounted, reactive, ref, toRefs} from "vue";
import TablePagination from "@/components/TablePagination.vue";
import Dialog from "@/modules/dialog.vue";
import request from "@/utils/request";
import SearchForm from "@/modules/search-form.vue";
import {ElForm, ElMessage} from "element-plus";
import store from "@/store";

export default defineComponent({
  components: {

    TablePagination,
    Dialog,
  },
  setup() {
    const eform = ref<null | typeof ElForm>();
    const table = ref<null | typeof TablePagination>();

    const arr: any[]=[];
    const f = {

      code:'',
      verifyStatus:'pass',
    };
    let state = reactive({
      verifyList:arr,
      companyList:arr,

      searchForm:{
        verifyStatus:'',
      },
      tableHeight:0,
      showEdit:false,
      isAdd:false,
      title:'',
      form: f,

    });

    onMounted(() => {
      getWindowsHeight();
      getData();
    });
    return {
      ...toRefs(state),
      searchClick,

      editClick,
      deleteClick,
      addClick,
      confirmEdit,

      table,
      eform,
      rules: {
        // userName:  { required: true, message: "用户名称不能为空", trigger: "blur" },
        // password:  { required: true, message: "密码不能为空", trigger: "blur" },
        // departmentId:  { required: true, message: "部门不能为空", trigger: "blur" },
        // phone:[
        //   {
        //     validator: (rule: any, value: any, callback: any) => {
        //
        //       if (value != '' && !(/^[1][3,4,5,7,8,9][0-9]{9}$/.test(value))) {
        //         callback(new Error("请输入正确的手机格式"));
        //       } else {
        //         callback();
        //       }
        //     },
        //     trigger: "blur",
        //   },
        // ],
        // idCard:[
        //   {
        //     validator: (rule: any, value: any, callback: any)  => {
        //
        //       if (value != '' && !(/^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[\d|X|x]$/.test(value))) {
        //         callback(new Error("请输入正确的身份证格式"));
        //       } else {
        //         callback();
        //       }
        //     },
        //     trigger: "blur",
        //   },
        // ],
      },

    };

    function getData() {
      request({
        url: '/enum',
        method: "GET",
        params: {},
      }).then((res) => {
        const d = (res as Record<string, any>).content;

        state.verifyList = d.userVerifyMap;
      });
    }

    function getWindowsHeight(){
      state.tableHeight = document.body.clientHeight-50-80-30;
    }
    function searchClick(){
      table.value?.getList();
    }

    function addClick(){
      state.showEdit = true;
      state.isAdd = true;
      state.form = JSON.parse(JSON.stringify(f));

      state.title = "新增";
    }

    function editClick(row:any){

      state.showEdit = true;
      state.isAdd = true;
      state.form = JSON.parse(JSON.stringify(row));

      state.title = "编辑";
    }
    function deleteClick(row:any){

      request({
        url: "/admin/delete/enum",
        method: "POST",
        data: row,
      }).then((res) => {
        const d = res as Record<string, any>;
        ElMessage.success(d.text);

        table.value?.getList();
      });
    }

    function confirmEdit(){
      eform.value?.validate((valid: boolean) => {
        if (valid) {

          request({
            url:  '/admin/save/enum',
            method: "POST",
            data: JSON.stringify(state.form),
            headers:{
              'Content-Type':'application/json'
            }
          }).then((res) => {
            const d = res as Record<string, any>;
            ElMessage.success(d.text);

            state.showEdit = false;
            table.value?.getList();
          });
        }
      });
    }

  },
});
